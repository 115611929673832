import BaseCrudService from "./baseCrud.service";

export default class EntityService extends BaseCrudService {

    constructor() {
        super('Entity');
    }

    setEntityStatus(entityId, entityStatusId){
        return this.putRequest('SetEntityStatus', {id: entityId, entityStatusId: entityStatusId})
    }

    findById(callback, entityId){
        return this.getRequest('FindById', {entityId: entityId}).then(callback);
    }
    findByCNPJAndEntityType(cnpj, entityType){
		return this.getRequest('FindByCNPJAndEntityType', {cnpj: cnpj, entityType: entityType});
	}
    
}