<template>
  <div>
    <v-layout>
      <div class="boxapp">
        <!-- boxapp head -->
        <div class="boxapp-head">
          <a href="#" @click="$router.go(-1)">
            <faIcon type="fal" name="long-arrow-left" size="24" colorFont="#cfd2dd" />
          </a>
          <h1>Perfil</h1>
        </div>
        <!-- boxapp body -->
        <div class="box-main">
          <div class="section">
            <!-- section head -->
            <div class="head mt-0">
              <h4>Dados Cadastrais</h4>
            </div>
            <!-- conteudo -->
            <div class="form-grid">
              <v-layout row wrap>
                <v-flex
                  class="upload-box-profile-father"
                  style="width: 300px !important"
                  shrink
                  pr-3
                >
                  <FileImageUpload
                    :maxWidth="300"
                    :maxHeight="300"
                    :quality="0.7"
                    :file="institution.file"
                    unselectedText="Logo da Instituição"
                  />
                  <!-- <div
                    class="upload-image bg-contain"
                    style="width:300px"
                    id="preview"
                    ref="preview"
                  >
                    <label for="upload-institution">
                      <i class="fas fa-camera-alt"></i>
                    </label>
                    <label
                      for="upload-institution"
                      class="do-upload"
                      v-show="institution.file.path == ''"
                    >
                      <i class="fal fa-cloud-upload"></i>Logo da Instituição
                    </label>
                    <input
                      type="file"
                      id="upload-institution"
                      accept="image/*"
                      @change="fileChanged($event, 0)"
                      ref="upload_institution"
                    />
                  </div>-->
                </v-flex>
                <v-flex form-grid xs12 md12 lg8 xl9>
                  <v-layout row wrap>
                    <v-flex xs12>
                      <InputText
                        ref="nameInput"
                        v-model="institution.entity.name"
                        type="text"
                        place-holder="Nome"
                        textLabel="Nome da instituição"
                        invalidInputMessage="Preencha o nome da instituição"
                        labelCharLimit="150"
                      />
                    </v-flex>
                    <v-flex d-flex align-center shrink>
                      <label>A empresa possui CNPJ?</label>
                    </v-flex>
                    <v-flex>
                      <v-radio-group v-model="isCNPJ" row>
                        <v-radio label="Não" class="m-470-t-16" :value="false"></v-radio>
                        <v-radio label="Sim" :value="true"></v-radio>
                      </v-radio-group>
                    </v-flex>
                    <v-flex xs12 sm6 v-if="isCNPJ">
                      <InputText
                        ref="cnpjInput"
                        v-model="institution.entity.cnpj"
                        type="tel"
                        place-holder="CNPJ"
                        textLabel="CNPJ"
                        invalidInputMessage="Preencha o CNPJ da instituição"
                        @input="verifyCNPJ"
                        mask="##.###.###/####-##"
                      />
                    </v-flex>
                    <v-flex xs12 sm6 v-else>
                        <InputText
                        v-model="institution.entity.cnpj"
                        type="tel"
                        place-holder="Identificação da instituição"
                        textLabel="Identificação da instituição"
                        invalidInputMessage="Preencha a identificação da instituição"
                      />
                    </v-flex>
                    <v-flex sm6 xs12>
                      <InputText
                        ref="socialReasonInput"
                        v-model="institution.entity.socialReason"
                        type="text"
                        place-holder="Razão Social"
                        textLabel="Razão Social"
                        invalidInputMessage="Preencha a razão social da instituição"
                        labelCharLimit="150"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap v-if="false">
                    <v-flex sm6 xs12>
                      <InputSelect
                        ref="ocupationAreaInput"
                        textLabel="Área de atuação"
                        v-model="institution.ocupationAreaId"
                        :items="ocupationAreaList"
                        valueAttribute="id"
                        textAttribute="description"
                        invalidInputMessage="Selecione a área de atuação da instituição"
                      ></InputSelect>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap v-if="false">
                    <v-flex xs12 sm6>
                      <InputText
                        ref="stateRegistryInput"
                        v-model="institution.stateRegistry"
                        type="number"
                        place-holder="Inscrição Estadual"
                        textLabel="Inscrição Estadual"
                        invalidInputMessage="Preencha a inscrição estadual da instituição"
                      />
                    </v-flex>
                    <v-flex xs12 sm6>
                      <InputText
                        ref="municialRegistryInput"
                        v-model="institution.municipalRegistry"
                        type="number"
                        place-holder="Inscrição Municipal"
                        textLabel="Inscrição Municipal"
                        invalidInputMessage="Preencha a inscrição municipal da instituição"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <BorderSeparation />
              <v-layout row>
                <v-flex xs12 md6>
                  <InputText
                    ref="postalCodeInput"
                    v-model="institution.entity.address.postalCode"
                    type="tel"
                    textLabel="CEP"
                    place-holder="CEP"
                    invalidInputMessage="Preencha o CEP da instituição"
                    :required="!hasPermission([permissions.CRUDInstitution])"
                    mask="#####-###"
                    @input="searchCEP()"
                  />
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 sm6>
                  <InputText
                    ref="streetInput"
                    v-model="institution.entity.address.street"
                    type="text"
                    textLabel="Endereço"
                    place-holder="Endereço"
                    invalidInputMessage="Preencha o endereço da instituição"
                    :required="!hasPermission([permissions.CRUDInstitution])"
                  />
                </v-flex>
                <v-flex xs12 sm3>
                  <InputText
                    ref="numberInput"
                    v-model="institution.entity.address.number"
                    type="number"
                    textLabel="Número"
                    place-holder="Número"
                    :required="false"
                  />
                </v-flex>
                <v-flex xs12 sm3>
                  <InputText
                    ref="complementInput"
                    v-model="institution.entity.address.complement"
                    type="text"
                    textLabel="Complemento"
                    place-holder="Complemento"
                    :required="false"
                  />
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 sm6>
                  <InputText
                    ref="neighborhoodInput"
                    v-model="institution.entity.address.neighborhood"
                    type="text"
                    textLabel="Bairro"
                    place-holder="Bairro"
                    invalidInputMessage="Preencha o bairro da instituição"
                    :required="!hasPermission([permissions.CRUDInstitution])"
                  />
                </v-flex>
                <v-flex xs12 sm3>
                  <InputText
                    ref="cityInput"
                    v-model="institution.entity.address.city"
                    type="text"
                    textLabel="Cidade"
                    place-holder="Cidade"
                    invalidInputMessage="Preencha a cidade da instituição"
                    :disabled="true"
                    :required="!hasPermission([permissions.CRUDInstitution])"
                  />
                </v-flex>
                <v-flex xs12 sm3>
                  <InputSelect
                    ref="stateInput"
                    :items="stateList"
                    v-model="institution.entity.address.state"
                    textLabel="Estado"
                    valueAttribute="name"
                    textAttribute="name"
                    invalidInputMessage="Preencha o estado da instituição"
                    :disabled="true"
                    :required="!hasPermission([permissions.CRUDInstitution])"
                  ></InputSelect>
                </v-flex>
              </v-layout>
              <BorderSeparation />
              <div class="head mt-0">
                <h4>Dados Cadastrais</h4>
              </div>
              <!-- <v-layout>
                <v-flex>
                  <InputTextArea
                    ref="scopeInput"
                    v-model="institution.scope"
                    class="mt-3"
                    textLabel="Escopo"
                    labelAreaCharLimit="500"
                    invalidInputMessage="Preencha o escopo"
                    :required="!hasPermission([permissions.CRUDInstitution])"
                  ></InputTextArea>
                </v-flex>
              </v-layout>-->
              <v-layout row wrap>
                <v-flex xs12 sm6>
                  <InputText
                    ref="yearFundationInput"
                    v-model="institution.yearFundation"
                    type="text"
                    textLabel="Ano de fundação"
                    :required="false"
                    mask="####"
                  />
                </v-flex>
                <v-flex xs12 sm6>
                  <InputText
                    ref="siteInput"
                    v-model="institution.site"
                    type="text"
                    textLabel="Site"
                    place-holder="URL"
                    :required="false"
                  />
                </v-flex>
                <v-flex sm6 xs12>
                  <InputText
                    ref="facebookInput"
                    v-model="institution.facebook"
                    type="text"
                    textLabel="Página no facebook"
                    :required="false"
                  />
                </v-flex>
                <v-flex sm6 xs12>
                  <InputText
                    ref="videoURLInput"
                    v-model="institution.videoURL"
                    type="text"
                    textLabel="Vídeo Institucional"
                    place-holder="URL"
                    :required="false"
                  />
                </v-flex>
                <v-flex sm6 xs12>
                  <InputText
                    ref="instagramInput"
                    v-model="institution.instagram"
                    type="text"
                    textLabel="Instagram"
                    :required="false"
                  />
                </v-flex>
                <v-flex sm6 xs12>
                  <InputText
                    ref="twitterInput"
                    v-model="institution.twitter"
                    type="text"
                    textLabel="Twitter"
                    :required="false"
                  />
                </v-flex>
              </v-layout>
              <BorderSeparation />
              <div class="head mt-0">
                <h4>Dados Ministério do Esporte</h4>
              </div>
              <v-layout align-content-center flex-column>
                <v-flex d-flex align-center shrink>
                  <label>Possui login no Ministério do Esporte ?</label>
                </v-flex>
                <v-flex>
                  <v-radio-group v-model="institution.hasLoginSportMinistery" row>
                    <v-radio label="Não" class="m-470-t-16" :value="false"></v-radio>
                    <v-radio label="Sim" :value="true"></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>

              <v-layout row wrap v-if="institution.hasLoginSportMinistery">
                <v-flex sm6 xs12>
                  <InputText
                    ref="loginMinEsporte"
                    v-model="institution.loginSportMinistery"
                    type="text"
                    textLabel="Usuário"
                    place-holder="Digite seu usuário"
                    helpLabel="Login no ministério do esporte"
                    :required="institution.hasLoginSportMinistery"
                    invalidInputMessage="Preencha o login"
                  />
                </v-flex>
                <v-flex sm6 xs12 pl-2>
                  <InputPassword
                    ref="passwordMinEsporte"
                    v-model="institution.passwordSportMinistery"
                    textLabel="Senha"
                    type="password"
                    place-holder="Digite sua senha"
                    helpLabel="Senha no Ministério do Esporte"
                    :force="false"
                    :required="institution.hasLoginSportMinistery"
                    invalidInputMessage="Preencha a senha"
                  />
                </v-flex>
              </v-layout>

              <BorderSeparation />
              <div class="head mt-0">
                <h4>Integração com SalesForce</h4>
              </div>
              <v-layout row mw-half>
                <v-flex>
                  <InputSelect
                    :items="[
                      { id: null, name: 'Sem integração' },
                      { id: 1, name: 'OSESP' },
                    ]"
                    valueAttribute="id"
                    textAttribute="name"
                    v-model="institution.salesForceIntegrationId"
                    textLabel="Integração com SalesForce"
                  ></InputSelect>
                </v-flex>
              </v-layout>

              <template v-if="showFinancialInformationBlock">
                <BorderSeparation />
                <div class="head mt-0">
                  <h4>Informações Financeiras</h4>
                </div>
                <div class="yes-no profile">
                  <v-layout align-content-center>
                    <v-flex d-flex align-center style="width:200px" shrink>
                      <label>Possui titulo Municipal ?</label>
                    </v-flex>
                    <v-flex>
                      <v-radio-group v-model="institution.municipalTitle" row>
                        <v-radio label="Sim" :value="true"></v-radio>
                        <v-radio label="Não" class="m-470-t-16" :value="false"></v-radio>
                      </v-radio-group>
                    </v-flex>
                  </v-layout>
                </div>
                <div class="yes-no profile mt-2">
                  <v-layout align-content-center>
                    <v-flex d-flex align-center style="width:200px" shrink>
                      <label>Possui titulo Estadual ?</label>
                    </v-flex>
                    <v-flex>
                      <v-radio-group v-model="institution.stateTitle" row>
                        <v-radio label="Sim" :value="true"></v-radio>
                        <v-radio label="Não" class="m-470-t-16" :value="false"></v-radio>
                      </v-radio-group>
                    </v-flex>
                  </v-layout>
                </div>
                <div class="yes-no profile mt-2">
                  <v-layout align-content-center>
                    <v-flex d-flex align-center style="width:200px" shrink>
                      <label>Possui titulo Federal ?</label>
                    </v-flex>
                    <v-flex>
                      <v-radio-group v-model="institution.federalTitle" row>
                        <v-radio label="Sim" :value="true"></v-radio>
                        <v-radio label="Não" class="m-470-t-16" :value="false"></v-radio>
                      </v-radio-group>
                    </v-flex>
                  </v-layout>
                </div>
                <div class="yes-no profile mt-2">
                  <v-layout align-center>
                    <v-flex d-flex align-center style="width:200px" shrink>
                      <label>Tem auditoria ?</label>
                    </v-flex>
                    <v-flex>
                      <v-radio-group v-model="institution.hasAudict" row>
                        <v-radio label="Sim" :value="true"></v-radio>
                        <v-radio label="Não" class="m-470-t-16" :value="false"></v-radio>
                      </v-radio-group>
                    </v-flex>
                  </v-layout>
                </div>
              </template>
              <template v-if="showDocumentsBlock">
                <BorderSeparation />
                <div class="head mt-0">
                  <h4>Documentos</h4>
                </div>
                <div
                  class="box-upload"
                  :key="institutionDocumentType.id"
                  v-for="institutionDocumentType in institutionDocumentTypeList"
                >
                  <div class="name-doc-institution">
                    <h4>{{institutionDocumentType.name}}</h4>
                    <span>{{files[institutionDocumentType.id].file.name}}</span>
                  </div>
                  <div class="archive-doc-institution">
                    <InputDate
                      place-holder="Vencimento"
                      :key="'validDate' + institutionDocumentType.id"
                      v-model="files[institutionDocumentType.id].validDate"
                      :min="$moment().format('YYYY-MM-DD')"
                      class="mr-3"
                    ></InputDate>
                    <div class="upload-bar-gallery w-100">
                      <label :for="'upload-statute' + institutionDocumentType.id">
                        <i class="fal fa-cloud-upload"></i>
                      </label>
                      <input
                        type="file"
                        accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .html, .xlsx, .xls, .csv"
                        :id="'upload-statute' + institutionDocumentType.id"
                        @change="fileChanged($event, institutionDocumentType.id)"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="box-help-spacer"></div>
      <HelpBox :text="helpBoxText" />
    </v-layout>
    <div class="footer-step">
      <v-btn
        flat
        round
        color="white"
        class="btn-primary flex-inherit px-5 ml-auto"
        large
        @click="save"
      >Próximo</v-btn>
    </div>
  </div>
</template>

<script type="plain/text">
import FooterStep from "@/components/FooterStep.vue";
import InputPassword from "@/components/input/InputPassword.vue";
import FileImage from "@/components/FileImage.vue";
import Institution from "@/scripts/models/institution.model.js";
import File from "@/scripts/models/file.model";
import Entity from "@/scripts/models/entity.model.js";
import Address from "@/scripts/models/address.model.js";
import FileInstitution from "@/scripts/models/fileInstitution.model.js";
import InstitutionService from "@/scripts/services/institution.service.js";
import EntityService from "@/scripts/services/entity.service.js";
import LocationService from "@/scripts/services/location.service.js";
import FileService from "@/scripts/services/file.service.js";
import ValidatableComponent from "@/components/base/ValidatableComponent.vue";
import CommonHelper from "@/scripts/helpers/common.helper";
import HelpBox from "@/components/HelpBox.vue";
import Permissions from "@/scripts/models/enums/permissions.enum";
import { HelpBoxText } from "@/scripts/models/enums/helpBoxText.enum.js";
import { Routes } from "@/scripts/models/enums/routes.enum";
import { setTimeout } from "timers";
import { mapGetters } from "vuex";
import ImageCompressor from "image-compressor.js";
import store from "@/store.js";

import {
  EntityType,
  EntityTypeList
} from "@/scripts/models/enums/entityType.enum";

export default {
  extends: ValidatableComponent,
  components: {
    HelpBox,
    InputPassword,
    FooterStep,
    FileImage
  },
  props: ["pageOptions"],
  computed: {
    ...mapGetters(["hasPermission"])
  },
  data() {
    return {
      helpBoxText: HelpBoxText.InstitutionProfile,
      permissions: Permissions,
      institutionService: new InstitutionService(),
      fileService: new FileService(),
      entityService: new EntityService(),
      fileInstitution: new FileInstitution(),
      locationService: new LocationService(),
      institution: new Institution(),
      cnpjUnique: true,
      showDocumentsBlock: false,
      showFinancialInformationBlock: false,
      fileInstitutionList: [],
      institutionDocumentTypeList: [],
      ocupationAreaList: [],
      stateList: [],
      files: {},
      cepValidation: true,
      breadcrumbLevels: [
        { text: "Início" },
        { text: "Instituições" },
        { text: "Perfil" }
      ],
      isCNPJ: true,
    };
  },
  beforeRouteEnter: (to, from, next) => {
    let hasPermission = false;
    if (to.query.institutionId && to.query.institutionId != 0) {
      if (
        store.getters.hasPermission(
          [Permissions.UpdateInstitution],
          to.query.entityId
        ) ||
        store.getters.hasPermission([Permissions.CRUDInstitution])
      ) {
        hasPermission = true;
      } else {
        hasPermission = false;
      }
    } else {
      if (store.getters.hasPermission([Permissions.CRUDInstitution])) {
        hasPermission = true;
      } else {
        hasPermission = false;
      }
    }
    if (hasPermission) next();
    else {
      store.commit(
        "SET_SNACKBAR_MESSAGE",
        "Você não tem permissão para acessar essa página."
      );
      next(false);
    }
  },
  created() {
    this.$emit("changeLevels", this.breadcrumbLevels);
    this.pageOptions.changeClass("footer");
    this.institutionService.listAllOcupationArea(
      this.listAllOcupationAreaCallback
    );
    this.institutionService.listAllState(this.listAllStateCallback);
    this.validationMethod = this.validate;
    if (this.$route.query != null && this.$route.query.institutionId != null) {
      this.institutionService.find(
        this.findInstitutionCallback,
        this.$route.query.institutionId
      );
    } else {
      this.institutionService.listAllInstitutionDocumentType(
        this.listAllInstitutionDocumentTypeCallback
      );
    }
  },
  methods: {
    verifyCNPJ() {
      if (this.institution.entity.cnpj.length < 14) return false;
      this.entityService
        .findByCNPJAndEntityType(
          this.institution.entity.cnpj,
          EntityType.Institution
        )
        .then(
          function(data) {
            if (data != null && this.institution.entity.id != data.id) {
              this.$store.commit(
                "SET_SNACKBAR_MESSAGE",
                "Já existe uma Instituição cadastrada com esse CNPJ"
              );
              this.cnpjUnique = false;
            } else {
              this.cnpjUnique = true;
            }
          }.bind(this)
        );
    },
    searchCEP() {
      if (
        this.institution.entity.address.postalCode == "" ||
        this.institution.entity.address.postalCode.length < 8
      )
        return false;
      this.$store.commit("SET_LOADER", true);
      this.locationService
        .searchCEP(this.institution.entity.address.postalCode)
        .then(data => {this.searchCEPCallback(data)});
    },
    searchCEPCallback(obj) {
      if (!obj.erro) {
        if (obj.street)
          this.institution.entity.address.street = obj.street;
        if (obj.neighborhood)
          this.institution.entity.address.neighborhood = obj.neighborhood;
        this.institution.entity.address.city = obj.city;
        this.institution.entity.address.state = obj.state;
        this.cepValidation = true;
      } else {
        this.institution.entity.address.street = "";
        this.institution.entity.address.neighborhood = "";
        this.institution.entity.address.city = "";
        this.institution.entity.address.state = "";
        this.cepValidation = false;
      }
      this.$store.commit("SET_LOADER", false);
    },
    validate(performMethods) {
      if (!this.hasPermission([Permissions.CRUDInstitution])) {
        // Validação de Logo
        if (this.institution.file == null || this.institution.file.path == "") {
          this.$store.commit(
            "SET_SNACKBAR_MESSAGE",
            "Escolha uma imagem para a instituição"
          );
          return false;
        }

        // Validação ano de fundação
        let year = this.$moment().format("YYYY");
        if (this.institution.yearFundation >= year) {
          this.$store.commit(
            "SET_SNACKBAR_MESSAGE",
            "O ano de fundação deve ser menor do que o ano atual"
          );
          return false;
        }

        // Validação de CEP
        if (!this.cepValidation) {
          this.$store.commit("SET_SNACKBAR_MESSAGE", "CEP Inválido");
          return false;
        }

        // Validação de Documentos
        // Retirada a obrigatoriedade somente para as primeiras fases do projeto
        // let invalidDocuments = this.institution.fileInstitutionList
        //   .map(fi => fi.file.path == "" || fi.validDate === null)
        //   .reduce((validDocuments, f) => {
        //     validDocuments = f ? f : false;
        //     return validDocuments;
        //   }, false);

        // if (invalidDocuments) {
        //   this.$store.commit(
        //     "SET_SNACKBAR_MESSAGE",
        //     "Preencha todos os dados de documentos"
        //   );
        //   return false;
        // }
      }

      if (this.institution.entity.address.postalCode != "") {
        if (!this.cepValidation) {
          this.$store.commit("SET_SNACKBAR_MESSAGE", "CEP Inválido");
          return false;
        }
      }

      let valid = this.validateComponents(performMethods);

      if (this.isCNPJ) {
        if (
          this.institution.entity.cnpj != "" &&
          !CommonHelper.validateCNPJ(this.institution.entity.cnpj)
        ) {
          this.$store.commit("SET_SNACKBAR_MESSAGE", "CNPJ inválido");
          return false;
        } else if (!this.cnpjUnique) {
          this.$store.commit(
            "SET_SNACKBAR_MESSAGE",
            "Já existe uma Instituição cadastrada com esse CNPJ"
          );
          return false;
        }
      }


      if (!valid) return false;
      return true;
    },
    listAllFileInstitutionByInstitutionIdCallback(data) {
      if (data.length != 0) this.institution.fileInstitutionList = data;
      data.forEach(el => {
        this.files[el.institutionDocumentTypeId] = el;
      });
      this.institutionService.listAllInstitutionDocumentType(
        this.listAllInstitutionDocumentTypeCallback
      );
    },
    findInstitutionCallback(data) {
      this.institution = data;
      if (this.institution.entity == null)
        this.institution.entity = new Entity();
      if (this.institution.entity.address == null)
        this.institution.entity.address = new Address();
      if (this.institution.file == null) this.institution.file = new File();
      // else {
      //   this.fileService
      //     .render(this.institution.file.id)
      //     .then(this.renderCallback);
      // }
      this.institutionService
        .listAllFileInstitutionByInstitutionId(this.$route.query.institutionId)
        .then(this.listAllFileInstitutionByInstitutionIdCallback);
    },
    renderCallback(data) {
      // this.$refs.preview.style.backgroundImage = "url(" + data + ")";
    },
    listAllInstitutionDocumentTypeCallback(data) {
      this.files = {};
      if (data) {
        for (let i = 0; i < data.length; i++) {
          let institutionDocumentTypeId = data[i].id;
          let fileInstitution = this.institution.fileInstitutionList.filter(
            f => f.institutionDocumentTypeId == institutionDocumentTypeId
          );
          if (!fileInstitution || fileInstitution.length == 0) {
            fileInstitution = new FileInstitution();
            fileInstitution.institutionDocumentTypeId = institutionDocumentTypeId;
            fileInstitution.institutionId = this.institution.id;
            this.institution.fileInstitutionList.push(fileInstitution);
          } else {
            fileInstitution = fileInstitution[0];
          }
          this.files[institutionDocumentTypeId] = fileInstitution;
        }
        this.institutionDocumentTypeList = data;
      }
    },
    listAllOcupationAreaCallback(data) {
      this.ocupationAreaList = data;
    },
    listAllStateCallback(data) {
      this.stateList = data;
    },
    getFileInstitution(institutionDocumentTypeId) {
      return fileInstitution;
    },
    fileChanged(event, id) {
      var file = event.target.files[0];
      this.files[id].file.name = file.name;
      let reader = new FileReader();
      reader.onload = function() {
        this.files[id].file.path = reader.result;
      }.bind(this);
      reader.readAsDataURL(file);
    },
    save() {
      if (this.isValid())
        this.institutionService.save(this.saveCallback, this.institution);
    },
    saveCallback(data) {
      this.$router.push({
        name: Routes.app.InstitutionAbout,
        query: { institutionId: data.id }
      });
    }
  }
};
</script>
