import File from "@/scripts/models/file.model";
export default class FileInstitution {

    constructor() {

        this.id = 0;
        this.institutionId = 0;
        this.file = new File();
        this.institutionDocumentTypeId = 0;
        this.validDate = null;
        
    }
}